import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  query,
  where,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import "./SubmitCompanyForm.css";

// Firebase Configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

function RequestAddCompany() {
  const [organization_name, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [url, setWebsite] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [logo, setLogo] = useState(null);
  const [industry, setIndustries] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  // Fetch industries from Firestore
  useEffect(() => {
    const fetchCompanies = async () => {
      const companyCollection = collection(db, "companies");
      const companySnapshot = await getDocs(companyCollection);
      const companyList = companySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Extract and set unique industries
      const allIndustries = Array.from(
        new Set(
          companyList
            .flatMap((company) => {
              if (typeof company.industry === "string") {
                try {
                  const parsedIndustry = JSON.parse(company.industry);
                  return Array.isArray(parsedIndustry)
                    ? parsedIndustry
                    : [parsedIndustry];
                } catch (e) {
                  return [company.industry];
                }
              } else if (Array.isArray(company.industry)) {
                return company.industry;
              } else {
                return [];
              }
            })
            .filter((industry) => industry.trim() !== ""),
        ),
      );

      setIndustries(allIndustries);
    };

    fetchCompanies();
  }, []);

  const handleIndustryChange = (event) => {
    const { value, checked } = event.target;
    setSelectedIndustries((prevSelected) =>
      checked
        ? [...prevSelected, value]
        : prevSelected.filter((ind) => ind !== value),
    );
  };

  const handleLogoChange = (e) => {
    setLogo(e.target.files[0]);
    console.log("Logo selected: ", e.target.files[0]);
  };

  const validateForm = () => {
    const validationErrors = {};
    let isValid = true;

    if (!organization_name.trim()) {
      validationErrors.organization_name = "Company Name is required.";
      isValid = false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim() || !emailPattern.test(email)) {
      validationErrors.email = "Please enter a valid email address.";
      isValid = false;
    }

    if (!url.trim()) {
      validationErrors.url = "Website is required.";
      isValid = false;
    }

    if (!phoneNumber.trim()) {
      validationErrors.phoneNumber = "Phone Number is required.";
      isValid = false;
    }

    setErrors(validationErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted!");

    if (!validateForm()) {
      console.log("Validation failed:", errors);
      return;
    }

    let logoUrl = null;
    if (logo) {
      try {
        const logoRef = ref(storage, `companyrequestlogo/${organization_name}/${logo.name}`);
        await uploadBytes(logoRef, logo);
        logoUrl = await getDownloadURL(logoRef);
        console.log("Logo uploaded successfully: ", logoUrl);
      } catch (error) {
        console.error("Error uploading logo: ", error);
      }
    }

    try {
      const newRequest = {
        organization_name,
        email,
        phone_number: phoneNumber,
        url,
        logo: logoUrl,
        industry: selectedIndustries,
        status: "pending new",
        createdAt: new Date(),
      };

      console.log("Submitting company request...");
      await addDoc(collection(db, "companyRequests"), newRequest);
      console.log("Company request submitted!");

      await addDoc(collection(db, "mail"), {
        to: ["eng@actualreality.tech"],
        from: "justin@actualreality.tech",
        message: {
          subject: `New Company Request: ${organization_name}`,
          html: `
            <h1>New Company Request</h1>
            <p><strong>Company Name:</strong> ${organization_name}</p>
            <p><strong>Website:</strong> ${url}</p>
            <p><strong>Contact Email:</strong> ${email}</p>
            <p>Please review the request in Firestore.</p>
          `,
        },
      });
      console.log("Email triggered successfully!");

      setMessage("Your request was submitted! We will approve it within two weeks.");
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting request or triggering email: ", error);
      setMessage("Error submitting request. Please try again.");
    }
  };

  return (
    <div className='container'>
      <div className="row mb-4">
        <h3>Add / Claim / Update Company</h3>
      </div>
      {submitted && (
        <div className='row'>
          <h2>{message}</h2>
        </div>
      )}
      {!submitted && (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <label className="form-label">
              Company Name:
              <input
                className="form-control-lg"
                type="text"
                value={organization_name}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
              {errors.organization_name && (
                <p className="error">{errors.organization_name}</p>
              )}
            </label>
          </div>

          <div className="row">
            <label className="form-label">
              Contact Email:
              <input
                className="form-control-lg"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </label>
          </div>

          <div className="row">
            <label className="form-label">
              Website:
              <input
                className="form-control-lg"
                type="url"
                value={url}
                onChange={(e) => setWebsite(e.target.value)}
                required
              />
              {errors.url && <p className="error">{errors.url}</p>}
            </label>
          </div>

          <div className="row">
            <label className="form-label">
              Phone Number:
              <input
                className="form-control-lg"
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
              {errors.phoneNumber && (
                <p className="error">{errors.phoneNumber}</p>
              )}
            </label>
          </div>

          <div className="row">
            <label className="form-label">
              Upload Company Logo:
              <input className="form-control" type="file" accept="image/*" onChange={handleLogoChange} />
            </label>
          </div>

          {/* Industry Selection */}
          <label className="form-label">
            Select Industries:
          </label>
          <div className="industry-options">
            {industry.length === 0 && (
              <p>No industries available.</p>
            )}
            {industry.length > 0 && (
              industry.map((industry, index) => (
                <label key={index}>
                  <input
                    type="checkbox"
                    value={industry}
                    checked={selectedIndustries.includes(industry)}
                    onChange={handleIndustryChange}
                  />
                  {industry}
                </label>
              ))
            )}
          </div>
          <button className='btn' type="submit">Submit Request</button>
        </form>
      )}
    </div>
  );
}

export default RequestAddCompany;
